.active button {
    background-color: #0b739c !important;
    color: white !important;
}

/* .carusel *{
    z-index: -10;
} */

.startGrosButton {
    transition: 0.2s;
    cursor: pointer;
}

.kard {
    transition: 0.2s;
}

.kard:hover {
    box-shadow: 0px -5px 20px !important;
    transform: scale(1.01);
}

.footer a {
    border-left: 3px solid #0b739c;
    padding: 0 5px;
    transition: 0.2s;
}

.footer a:hover {
    text-shadow: 0px 0px 1px white;
    border-left: 15px solid #0b739c;
}

.footer svg {
    font-size: 30pt;
    margin: 0 0 5px 5px;
    color: #446b7e;
}

.footer svg:hover {
    cursor: pointer;
}

.neu {
    transition: 0.2s;
    position: fixed !important;
    z-index: 100 !important;
    bottom: 46% !important;
    right: 0 !important;
    cursor: pointer;
    background-color: white;
    border-left: 5px solid #0b739c;
}

.mapDiv {
    background-image: url(./image/map.png);
    background-size: cover;
}

.mapDiv h2 {
    text-shadow: -1px -1px 1px black;
}

.submitButton {
    transition: 0.2s;
    background-color: #ffffff;
    border: 1px solid gray;
    color: #0b739c;
    padding: 10px;
    border-radius: 2px;
}

.submitButton:hover {
    background-color: #0b739c;
    color: white;
}

.submitButton:active {
    box-shadow: 0px 2px 14px 0px black;
}

.abteilungText {
    font-size: 9pt;
}

.cockieButton {
    border: none;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, .5);
    transition: 0.1s;
    font-size: 20px;
    background-color: #1399a3;
    color: white;
}

/* ######################################## loader */

.loaderDiv {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex !important;
    z-index: 1000 !important;
    position: fixed !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-tap-highlight-color: transparent !important;
}

.loader {
    z-index: 10000 !important;
    width: 120px !important;
    height: 120px !important;
    border: 16px solid #0d4981 !important;
    border-radius: 50% !important;
    border-top: 16px solid white !important;
    border-right: 16px solid #0d4981 !important;
    border-bottom: 16px solid white !important;
    -webkit-animation: spin 2s linear infinite !important;
    animation: spin 0.5s linear infinite !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* ######################################## loader */

/* Galery Images */

.react-photo-gallery--gallery img {
    object-fit: cover;
    transition: 0.1s;
}

.react-photo-gallery--gallery img:hover {
    filter: brightness(0.6)
}

/* WEIS NICHT WARUM ICH DAS GEMACHT HABE */

.image-gallery-image img {
    height: 100px !important;
    object-fit: cover;
}

.image-gallery-thumbnail-image img {
    height: 7000px !important;
    object-fit: cover !important;
}

/* Galery Images */

.sprechen button {
    width: 50px;
    height: "auto";
    border: none !important;
    font-size: 0.875rem !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    background-color: white !important;
}

.sprechen button:hover {
    background-color: #0d4981 !important;
}

.rs-container {
    width: auto !important;
}

.downloads a {
    border: 1px solid rgb(170, 170, 170);
    padding: 5px;
}

.downloads * {
    transition: 0.1s;
}

.downloads a:hover {
    text-decoration: underline !important;
    color: white;
    background-color: #0b739c;
}

.startTextErsterWort {
    font-size: 30pt !important;
}

.startAktuellesText {
    z-index: 1000;
    color: white;
    font-weight: 900;
    padding: 10px;
    cursor: pointer;
    background-color: #006eb3e7 !important;
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.startAktuellesText h5 {
    margin: 10px 0 0 0;
}

.startAktuellesText h5:hover {
    color: #0b739c;
    text-shadow: 1px 1px 1px white;
    text-decoration: underline;
}

.startText {
    background-image: url(../css//image/hintergrund.png);
}

.slick-dots li button:before {
    font-size: 30px !important;
}

.slick-arrow {
    display: none !important;
}

.anfangbuchstabegross p::first-letter {
    color: #0b739c;
    font-size: 20pt;
    font-weight: bold;
    font-family: 'Lemonada', cursive !important;
}

/* Galery Images */

.react-photo-gallery--gallery img {
    object-fit: cover;
    transition: 0.1s;
    padding: 5px !important;
    border-radius: 8px;
}

.react-photo-gallery--gallery img:hover {
    filter: brightness(0.6)
}

.react-images__navigation button {
    background-color: transparent !important;
}

.menuItem {
    line-height: normal;
}

.start-card {
    transition: 0.3s;
}

.start-card img {
    height: 150px;
    object-fit: cover;
}

.start-card:hover {
    scale: 1.05;
}

.winachtstext {
    color: #ef7474;
    text-shadow: 1px 1px 1px #505050;
    font-size: 24pt;
    text-align: center;
}

.start-video {
    width: 100vw;
    height: calc(100vh - 250px);
    object-fit: cover;
    z-index: -1;
    filter: brightness(0.6);
}

.start-menu {
    width: 100%;
    top: 30%;
}