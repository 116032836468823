@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lemonada:wght@300&display=swap');


body * {
    outline: none !important;

}

body {
    background-color: rgb(245, 245, 245);
    overflow-x: hidden !important;
    /* background-image: url(./image//hintergrund.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;  */

    /* background-image: url(./image/hintergrund.png);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: contain;  */



}

a {
    text-decoration: none !important;
    color: inherit;
}

a:hover {
    text-decoration: none !important;
    color: inherit;
}

.scroll {
    overflow: auto;
}

.cover {
    object-fit: cover;
}

.pointer {
    cursor: pointer;
}

.nowrap {
    white-space: nowrap
}

.bg-yellow {
    background-color: rgb(255, 196, 0);
}

.text-yellow {
    color: rgb(255, 196, 0);
}

.text-lila {
    color: #673ab7 !important;
}

.border-lila {
    border-color: #673ab7 !important;
}


.block {
    padding: 80px 0;
    min-height: 500px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.block-small {
    padding: 40px 0;
    min-height: 250px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}



/* ######################## COLORS */
.text-info {
    color: #009bd0 !important;
}

.bg-info {
    background-color: #009bd0 !important;
}

.text-success {
    color: #22b322 !important;
}

.bg-success {
    background-color: #22b322 !important;
}

.text-warning {
    color: #feb957 !important;
}

.bg-warning {
    background-color: #fc9547 !important;
}

.bg-lila {
    background-color: #893db7 !important;
}

.bg-gray {
    background-color: #929292 !important;
}

.text-gray {
    color: #929292 !important;
}

.bg-danger {
    background-color: #e40065 !important;
}

.text-danger {
    color: #e40065 !important;
}

.border-gray {
    border-color: #929292 !important;
}

/* ######################## COLORS */



.vertical-timeline-element-date {
    color: rgb(88, 88, 88);
}

.ff-1 {
    font-family: 'Public Sans', sans-serif !important;
}

.ff-2 {
    font-family: 'Poppins', sans-serif !important;
}

.ff-3 {
    font-family: 'Lemonada', cursive !important;
}