@media screen and (max-width: 500px) {

    span,
    p,
    a {
        font-size: small !important;
    }


    h3 {
        font-size: 11pt !important;
    }

    h4 {
        font-size: 10pt !important;
    }

    .abteilungText {
        font-size: xx-small;
    }

    .startAktuellesText {
        top: 65%;
    }

    .start-icon {
        display: none;
    }

}

@media screen and (max-width: 1200px) {

    .indexMenu span,
    p {
        font-size: small;
    }
}